.marquee-container {
    overflow: hidden;
    white-space: nowrap;
    /* background-color: #f04545; */
    /* Optional background */
    padding: 10px 0px;
}

.marquee {
    display: inline-block;
    animation: scroll-left 12s linear infinite;
}

.marquee-item {
    display: inline-block;
    margin: 0 25px;
}

.marquee img {
    max-height: 50px;
    /* Adjust as needed */
    display: inline-block;
    object-fit: contain;
    /* Keep image aspect ratio */
}

@keyframes scroll-left {
    0% {
        transform: translateX(100%);
    }

    100% {
        transform: translateX(-100%);
    }
}