.swiper-horizontal>.swiper-pagination-bullets .swiper-pagination-bullet,
.swiper-pagination-horizontal.swiper-pagination-bullets .swiper-pagination-bullet {
    width: 16px !important;
    height: 4px !important;
    border-radius: 5px !important;
    margin: 0 6px !important;
    background: #0fff00;
}

.swiper-pagination {
    bottom: 2px !important;
}

.swiper-wrapper {
    height: max-content !important;
    width: max-content !important;
    gap: 20px;
    display: flex;
}

.swiper-pagination-bullet-active {
    background: #4F46E5 !important;
}

.swiper-slide.swiper-slide-active>.slide_active\:border-indigo-600 {
    --tw-border-opacity: 1;
    border-color: rgb(79 70 229 / var(--tw-border-opacity));
}

.swiper-slide.swiper-slide-active>.group .slide_active\:text-gray-800 {
    ---tw-text-opacity: 1;
    color: rgb(31 41 55 / var(--tw-text-opacity));
}