.custom-scroll::-webkit-scrollbar {
    width: 8px;
}

.custom-scroll::-webkit-scrollbar-thumb {
    background-color: #4A5568;
    /* Custom scrollbar color */
    border-radius: 8px;
}

.custom-scroll::-webkit-scrollbar-track {
    background-color: #EDF2F7;
    /* Track color */
}