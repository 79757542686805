/* src/components/toast.css */
.toast-container {
    position: fixed;
    top: 70px;
    right: 20px;
    z-index: 9999;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.toast {
    background-color: #f0f0f0;
    /* border-radius: 50px; */
    padding: 12px 60px;
    color: #333;

    font-size: 14px;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.2);
    opacity: 0;
    transform: translateX(100%);
    transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
}

.toast.success {
    background-color: #44ff70;
    border-left: 10px solid #02cb31;
    color: white;
}

.toast.error {
    background-color: #ff394d;
    border-left: 10px solid #d30116;
    color: white;
}

.toast.show {
    opacity: 1;
    transform: translateX(0);
}

.toast.hide {
    opacity: 0;
    transform: translateX(100%);
}